<template>
  <v-card :flat="flat"
          :color="transparent ? 'transparent' : ''"
          :dark="dark">
    <h5 v-text="$t('text.developer')" />
    <br>
    {{$t('text.name')}}: Qwips Service Solutions<br>
    <v-icon size="18"
            class="mr-1">mdi-email</v-icon> Support@qwips.no <br>
    <v-icon size="18"
            class="mr-1">mdi-phone</v-icon> +47 22 22 44 00 <br>
    <v-icon size="18"
            class="mr-1">mdi-link-variant</v-icon> www.qwips.no<br>
  </v-card>
</template>


<script>
export default {
  name: "DeveloperContactCard",
  props: {
    dark: Boolean,
    transparent: Boolean,
    flat: Boolean,
  },
  components: {},

  data() {
    return {};
  },

  methods: {},

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped>
</style>