<template>
    <div v-if="grid" class="d-flex flex-column align-center ma-2" style="width: 100px;">
        <!--
        <v-img v-if="file.IsMedia2" class="ma-2" :width="80" :src="file.Link">
        </v-img>
        -->
        <v-icon size="50"> {{ typeIcon() }}</v-icon>
        <p class="mb-0 fileText">{{ file.Name }}</p>
    </div>

    <v-list-item v-else dense style="min-height: 34px; max-width: calc(100% - 12px);" link :href="file.Link"
        target="_blank">
        <v-list-item-icon class="my-auto mr-3">
            <!--  
            <v-img v-if="file.IsMedia2" class="ma-2" :width="80" :src="file.Link">
            </v-img>
            -->
            <v-icon> {{ typeIcon() }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="py-0">
            <v-list-item-title>
                {{ file.Name }}
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">

        </v-list-item-action>
    </v-list-item>
</template>


<script>
export default {
    name: 'File',
    props: {
        grid: Boolean,
        file: Object,
    },
    components: {},

    data() {
        return {
            DocumentTypes: {
                LINK: 1,
                FORM: 2,
                PARTDRAWING: 3,
            },
        };
    },

    methods: {
        typeIcon() {
            if (this.file.IsMedia) {
                return 'mdi-file-image';
            }
            else if (this.file.TypeId == this.DocumentTypes.FORM) {
                return 'mdi-file-document-edit';
            }
            else if (this.file.TypeId == this.DocumentTypes.PARTDRAWING) {
                return 'mdi-file-cad';
            }
            else {
                return 'mdi-file-document';
            }
        }
    },

    computed: {

    },

    watch: {},
};
</script>


<style lang='scss' scoped>
.fileText {
    font-size: 12px;
    opacity: 0.9;
    overflow-wrap: break-word;
    inline-size: 100px;
    line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: center;
}
</style>