<template>
    <PaginatedList
        :getListMethod="getMachines"
        :optional-filers="{}"
        :paginationEnabled="true"
        :itemsPerPage="30"
        :grid="cardMode"
        :open-function="openItem"
        :pk="pk"
        :headerheight="56"
    >
        <template v-slot:header>
            <v-card
                v-if="isPcBool"
                class="rounded-0 px-3"
                flat
                style="z-index: 4"
            >
                <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                    style="height: 54px"
                >
                    <h3>{{ $t("text.your_equipment") }}</h3>
                    <!-- 
        <FilterSearch :pk="pk" />
        <v-btn>test</v-btn>
        -->
                </v-row>
            </v-card>
        </template>

        <template v-slot:list-item="{ item }">
            <v-col v-if="cardMode" cols="12" sm="6" md="4" lg="3" class="pa-2">
                <MachineOverviewCard
                    :item="item"
                    @click="openItem(item)"
                    @mount="getJobCounts(item)"
                >
                </MachineOverviewCard>
            </v-col>
            <MachineListItem v-else :item="item" showAlerts> </MachineListItem>
        </template>

        <template v-slot:footer>
            <Footer />
        </template>
    </PaginatedList>
</template>

<script>
import http from "@/api/client";
import MachineOverviewCard from "@/components/Cards/MachineOverviewCard.vue";
import Footer from "@/components/Footer.vue";
import MachineListItem from "@/components/ListItems/MachineListItem.vue";
import PaginatedList from "@/components/PaginatedList.vue";
import Vue from "vue";
//import ServiceProviderContactCard from "@/components/Cards/ServiceProviderContactCard.vue";
//import EmptyPage from "@/views/Customer/EmptyPage.vue";

export default {
    name: "MachineList",
    components: {
        MachineListItem,
        MachineOverviewCard,
        PaginatedList,
        Footer,
        //ServiceProviderContactCard,
        //EmptyPage,
    },

    data() {
        return {
            pk: "",
            machineTypes: [],
            machineSuppliers: [],
            parentMachineModels: [],
            machines: [],
            loading: false,
            search: "",
            cardMode: true,
        };
    },

    mounted() {
        //this.getMachines();
    },

    methods: {
        async getMachines(filter) {
            //console.log("getMachines", filter);
            this.loading = true;
            await http()
                .get(
                    "api2/customer/machines/filtered" +
                        this.serializeToQueryParams(filter)
                )
                .then((response) => {
                    this.machines = response.data.Items;
                    this.machineSuppliers = this.machines
                        .map((e) => e.SupplierName)
                        .filter((v, i, a) => a.indexOf(v) === i)
                        .sort();
                    this.machineTypes = this.machines
                        .map((e) => e.MachineType)
                        .filter((v, i, a) => a.indexOf(v) === i)
                        .sort();
                    this.parentMachineModels = this.machines
                        .map((e) => e.ParentMachineModelName)
                        .filter((v, i, a) => a.indexOf(v) === i)
                        .sort();
                    this.loading = false;
                });
            return this.machines;
        },

        getJobCounts(item) {
            http()
                .get("api2/customer/machines/openJobsCount/" + item.Id)
                .then((response) => {
                    var pjC = response.data.ActivePartJobsCount;
                    var sjC = response.data.ActiveOtherJobsCount;
                    item.ActivePartsJobs = pjC;
                    Vue.set(item, "ActiveServiceJobs", sjC);
                });
            console.log(item);
            return item;
        },

        openItem(item) {
            this.$router.push({ name: "ViewMachine", params: { id: item.Id } });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style scoped></style>
