<template>
  <v-container fluid class="pa-0 wrapper" :style="maxHeight + ' ' + minHeight">

    <div v-if="!loading" :class="['top', horizontal ? 'horizontal' : '', 'shadow', showTopShaddow ? 'visible' : '']">
    </div>

    <component :is="grid ? 'VRow' : 'VList'" v-scroll.self="handleScroll" no-gutters
      :align="loading ? 'center' : 'start'" :class="[horizontal ? 'horizontal' : '', listclass]"
      class="py-0 lista OddMarkedList" :style="maxHeight + ' ' + minHeight" ref="thelist" dense>
      <slot>
        <slot name="prepend" />

        <template v-if="limitedList && limitedList.length > 0 && loading === false">
          <template v-for="(item, index) in limitedList">
            <slot name="list-item" :item="item" :index="index" :handleclicked="handleclicked">
              <v-list-item @click="handleclicked(item)">
                item {{ index }}
              </v-list-item>
            </slot>
          </template>
        </template>

        <template v-else-if="loading === true" fluid class="pa-0">
          <template v-if="skeletonloading">
            <slot name="skeleton-loader" v-for="index in 10" :index="index">
              <v-skeleton-loader type="list-item" :style="'opacity:' + (1 / (index - 1)) + ';'"></v-skeleton-loader>
            </slot>
          </template>
          <centered-loading v-else :displayBool="true" :completedBool="false" :displayText="''" class="ignoreodd">
            <!-- $t('text.loading') -->
          </centered-loading>
        </template>

        <template v-else-if="loading === false">
          <slot v-if="list && unfilteredcount" name="empty-filtered-list">
            <empty-page :icon="'mdi-filter-remove'"
              :title="$t('servicesystem.common_objects.fallbackterms.empty_filter', { okey: $t('servicesystem.common_objects.commonterms.items').toLowerCase() })"
              :message="$t('servicesystem.common_objects.fallbackterms.empty_filter_body')" class="ignoreodd">
              <template v-slot:actions>
                <slot name="empty-filtered-list-actions" />
              </template>
            </empty-page>
          </slot>

          <slot v-else name="empty-list">
            <empty-page :icon="'mdi-clipboard-alert'" :title="$t('servicesystem.common_objects.fallbackterms.no_items')"
              :message="$t('servicesystem.common_objects.fallbackterms.found_no_items')" class="ignoreodd">
              <template v-slot:actions>
                <slot name="empty-list-actions" />
              </template>
            </empty-page>
          </slot>
        </template>

      </slot>

      <component :is="grid ? 'VRow' : 'div'" no-gutters
        v-if="paginationLimitNotReached && limitedList && limitedList.length">
        <template v-if="!grid && skeletonloading">
          <slot name="skeleton-loader" v-for="index in 4" :index="index">
            <v-skeleton-loader type="list-item" :style="'opacity:' + (1 / index) + ';'"></v-skeleton-loader>
          </slot>
        </template>
        <centered-loading v-else :displayBool="true" :completedBool="false" :displayText="''" class="ignoreodd">
          <!-- $t('text.loading') -->
        </centered-loading>
      </component>

      <component :is="grid ? 'VCol' : 'div'" v-if="!loading" cols="12">
        <slot name="footer" />
      </component>

    </component>
    <div v-if="!loading"
      :class="['bottom', horizontal ? 'horizontal' : '', 'shadow', showBottomShaddow ? 'visible' : '']">
    </div>

  </v-container>
</template>


<script>
import { debounce } from "debounce";
import EmptyPage from "@/views/EmptyPage.vue";
import CenteredLoading from "@/components/CenteredLoading.vue";
import { VList, VRow, VCol } from "vuetify/lib";

export default {
  name: "QScroll",
  props: {
    height: [String, Number],
    minheight: [String, Number],
    list: Array,
    unfilteredcount: Number,
    horizontal: Boolean,
    grid: Boolean,
    listclass: String,
    loading: Boolean,
    skeletonloading: Boolean,
    handleclicked: Function,
    pagination: Object, //{itemsPerPage: 50, nextPageLoading: false, maxItemCount: 200}
  },
  components: {
    VList,
    VRow,
    VCol,
    EmptyPage,
    CenteredLoading
  },
  mixins: [],

  data() {
    return {
      showTopShaddow: false,
      showBottomShaddow: false,
      renderMoreOnPercent: 90,
      limit: 10,
      page: 1,
      listChangeTrigger: 1,
    };
  },

  mounted() {
    var is = this.isOverflowing();
    this.showBottomShaddow = is;
    if (!is) {
      this.limit = this.limit * 2;
      this.showBottomShaddow = this.isOverflowing();
    }
  },

  methods: {
    handleScroll(e) {
      var position = this.horizontal ? e.target.scrollLeft : e.target.scrollTop;
      var offset = this.horizontal ? e.target.offsetWidth : e.target.offsetHeight;
      //var clienth = e.target.clientHeight;
      var bottom = this.horizontal ? e.target.scrollWidth : e.target.scrollHeight;
      this.showTopShaddow = position != 0 /*&& bottom > clienth*/;
      var scrollpercent = Math.round(((position + offset) / bottom) * 100);

      if (scrollpercent > this.renderMoreOnPercent) {
        //console.error(scrollpercent + "%");
        if (this.list && this.limit < this.list.length) {
          this.limit = this.limit + 1;
        }
        this.$emit("limitincrease", this.limit);
        //console.log("limitincrease", this.limit);
        //this.debounceEmit({ emitKey: 'limitincrease', value: this.limit });

        if (this.paginationLimitNotReached) {
          var loadeditems = this.pagination.page * this.pagination.itemsPerPage;
          var lessThanHalfPageLeft = ((this.limitedList && this.limitedList.length) || 0) > (loadeditems - (this.pagination.itemsPerPage * 0.5))
          if (lessThanHalfPageLeft) {
            this.debounceEmit({ emitKey: 'loadNextPage' });
          }
        }
      }
      this.showBottomShaddow = position + offset != bottom;
    },

    validateOverflow() {
      this.showBottomShaddow = this.isOverflowing();
    },

    isOverflowing() {
      var is;
      if (this.$refs.thelist) {
        var element = this.$refs.thelist;
        if (this.horizontal) {
          is = element.offsetWidth < element.scrollWidth;
        } else {
          is = element.offsetHeight < element.scrollHeight;
        }
      }
      return is;
    },

    debounceEmit: debounce(function ({ emitKey, value }) {
      //console.log('debounced', emitKey, value);
      this.$emit(emitKey, value);
    }, 700),
  },

  computed: {
    limitedList() {
      console.log('listChangeTrigger', this.listChangeTrigger);
      var maxlimit = (this.pagination && ((this.pagination.page + 1) * this.pagination.itemsPerPage)) || this.limit;
      var it = this.list && this.list.length > 0 ? this.list.slice(0, (maxlimit - 1)) : this.list;
      console.log('limitedList', maxlimit, it);
      return it;
    },

    paginationLimitNotReached() {
      var result = this.pagination && !this.pagination.partialPageReached
      //console.log('paginationLimitNotReached', result);
      return result;
    },

    maxHeight() {
      var limit = this.height;
      if (typeof limit === "string" || limit instanceof String) {
        return "max-height: " + limit + ";";
      } else if (limit) {
        return "max-height: " + limit + "px;";
      } else {
        return "";
      }
    },

    minHeight() {
      var limit = this.minheight;
      if (typeof limit === "string" || limit instanceof String) {
        return "min-height: " + limit + ";";
      } else if (limit) {
        return "min-height: " + limit + "px;";
      } else {
        return "";
      }
    },
  },

  watch: {
    list: function () {
      this.listChangeTrigger++;
      //console.log("list change event");
      setTimeout(() => this.validateOverflow(), 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.lista {
  overflow-y: overlay;
  z-index: 10;
}

.horizontal {
  display: flex;
  flex-direction: row;
  overflow-y: none;
  overflow-x: overlay;
}

@-moz-document url-prefix() {

  //Firefox does not support overlay overflow, and interprets it as none instead of auto.
  .lista {
    overflow-y: auto;
  }

  .horizontal {
    overflow-y: none;
    overflow-x: auto;
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
}

.shadow {
  position: absolute;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 10px;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.horizontal.shadow {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 15px;
  height: 100%;
  opacity: 0;
}

.shadow.visible {
  visibility: visible;
  opacity: 1;
}

.top.shadow {
  top: 0;
  background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.15), transparent);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.15), transparent);
}

.top.horizontal.shadow {
  left: 0 !important;
  right: auto;
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent);
}

.bottom.shadow {
  bottom: 0;
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), transparent);
}

.bottom.horizontal.shadow {
  left: auto;
  right: 0 !important;
  background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.25), transparent);
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), transparent);
}

.theme--light.v-sheet {
  background-color: transparent !important;
}
</style>