<template>
  <v-menu offset-x rounded="0" :close-on-content-click="false" right>
    <template v-slot:activator="{ on, attrs }">
      <v-card v-bind="attrs" v-on="on" color="rgb(0 0 0 / 5%)"
        class="pa-3 d-flex flex-column justify-center align-center" style="width: 100%" flat tile>
        <Avatar :fullname="userInfo.Name" color="red" class="mb-1" />
        {{ $t('text.account') }}
      </v-card>
      <!-- 
      <v-card min-width="100" max-width="300" color="rgb(0 0 0 / 4%)" v-bind="attrs" v-on="on" flat tile>
        <v-list-item class="pr-0" style="height: 63px !important;">
          <v-list-item-icon class="my-auto mr-4">
          
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ userInfo.Name }}</v-list-item-title>
            <v-list-item-subtitle>{{ userInfo.Email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      -->
    </template>

    <v-list class="py-0 overflow-y-overlay" style="max-height: calc(100vh - 64px); min-width: 250px;">
      <v-row justify="center" class="pt-6 pb-3" no-gutters>
        <Avatar :fullname="userInfo.Name" color="red" :large="true">
        </Avatar>
      </v-row>
      <div class="px-4">
        <v-text-field :label="$t('text.username')" :value="userInfo.Name" class="borderless-input" hide-details
          readonly>
        </v-text-field>
        <v-text-field :label="$t('text.email')" :value="userInfo.Email" class="borderless-input" hide-details readonly>
        </v-text-field>
      </div>
      <LanguageSelector class="mb-4 mt-3" />
      <v-divider></v-divider>
      <ResetPasswordDialog />
      <HoverListItem color="error" icon="mdi-logout-variant" :text="$t('text.logout')"
        @click="$store.dispatch('logout')">
      </HoverListItem>
    </v-list>
  </v-menu>
</template>


<script>
import Avatar from "@/components/Cards/Avatar.vue";
import ResetPasswordDialog from "@/components/Dialogs/ResetPasswordDialog.vue";
import HoverListItem from "@/components/ListItems/HoverListItem.vue";
import LanguageSelector from "@/components/Inputs/LanguageSelector.vue";

export default {
  name: "AccountButton",
  props: {},
  components: {
    Avatar,
    ResetPasswordDialog,
    HoverListItem,
    LanguageSelector,
  },

  data() {
    return {};
  },

  mounted() { },

  methods: {},

  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },

  watch: {},
};
</script>

<style lang="scss">
.borderless-input .v-input__control .v-input__slot::before {
  border-style: none !important;
}
</style>